<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>shop-sign-open</title>
    <path d="M4,17.625a.877.877,0,0,0,.875-.875v-3a.875.875,0,0,0-1.75,0v3A.877.877,0,0,0,4,17.625Z" />
    <path d="M9.875,13.75A.877.877,0,0,0,9,12.875H8.375a.25.25,0,0,0-.25.25v1.25a.25.25,0,0,0,.25.25H9A.877.877,0,0,0,9.875,13.75Z" />
    <path
      d="M24,11.25a2.5,2.5,0,0,0-2.5-2.5H17.509a.248.248,0,0,1-.215-.124L14.367,3.609a2.74,2.74,0,0,0-4.734,0L6.706,8.626a.248.248,0,0,1-.215.124H2.5A2.5,2.5,0,0,0,0,11.25v8a2.5,2.5,0,0,0,2.5,2.5h19a2.5,2.5,0,0,0,2.5-2.5Zm-9.5,3.375a.625.625,0,0,1,0,1.25H13.375a.25.25,0,0,0-.25.25V17.25a.375.375,0,0,0,.375.375h2a.625.625,0,0,1,0,1.25h-2a1.627,1.627,0,0,1-1.625-1.625v-4A1.627,1.627,0,0,1,13.5,11.625h2a.625.625,0,0,1,0,1.25h-2a.375.375,0,0,0-.375.375v1.125a.25.25,0,0,0,.25.25ZM6.875,12.25a.625.625,0,0,1,.625-.625H9a2.125,2.125,0,0,1,0,4.25H8.375a.25.25,0,0,0-.25.25V18.25a.625.625,0,0,1-1.25,0Zm10,0a.625.625,0,0,1,1.184-.279L19.4,14.656a.25.25,0,0,0,.474-.112V12.25a.625.625,0,0,1,1.25,0v6a.624.624,0,0,1-.481.608.59.59,0,0,1-.144.017.625.625,0,0,1-.559-.346L18.6,15.844a.25.25,0,0,0-.474.112V18.25a.625.625,0,0,1-1.25,0Zm-15,1.5a2.125,2.125,0,0,1,4.25,0v3a2.125,2.125,0,0,1-4.25,0Zm9.484-9.132a.774.774,0,0,1,1.282,0l2.191,3.756a.25.25,0,0,1-.216.376H9.384a.25.25,0,0,1-.216-.376Z"
    />
  </svg>
</template>
